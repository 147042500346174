import React from "react";
import css from './SectionHero.module.css';
import '../../styles/autocomplete-theme-classic.module.css';

const SectionSteps = props => {
    const {
        intl
    } = props;

    const steps = [
        {
            PageName: intl.formatMessage({ id: 'LandingPage.redirectionTab1' }),
            description: intl.formatMessage({ id: 'LandingPage.browserVendors' }),
        },
        {
            PageName: intl.formatMessage({ id: 'LandingPage.redirectionTab2' }),
            description: intl.formatMessage({ id: 'LandingPage.bidRespond' }),
        },
        {
            PageName: intl.formatMessage({ id: 'LandingPage.redirectionTab3' }),
            description: intl.formatMessage({ id: 'LandingPage.bestFitPay' }),
        },
        {
            PageName: intl.formatMessage({ id: 'LandingPage.redirectionTab4' }),
            description: intl.formatMessage({ id: 'LandingPage.leaveReview' }),
        },
    ];

    return (
        <div>
            <div className={css.stepsContainer}>
                {steps.map((step, index) => (
                    <div key={step.description + '_' + index} className={css.step}>
                        <div className={css.stepNumber}>{index + 1}</div>
                        <div className={css.stepDescription}>{step.description}</div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default SectionSteps;